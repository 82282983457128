import React, { FC } from "react";
import { ListItem, OrderedList, UnorderedList } from "@chakra-ui/react";

type BulletedListProps = {
  items: Array<string>;
  type: string;
};

const BulletedList: FC<BulletedListProps> = ({ items, type }) => {
  const getListItems = () => {
    const listItems = items.map((item) => <ListItem>{item}</ListItem>);
    return listItems;
  };

  return type === "unordered" ? (
    <UnorderedList>{getListItems()}</UnorderedList>
  ) : (
    <OrderedList>{getListItems()}</OrderedList>
  );
};

export default BulletedList;
